import "./assets/styles/_app.scss";
import "floating-vue/dist/style.css";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VModal from "vue-final-modal";
import Vuelidate from "vuelidate";
import VueMask from "v-mask";
import Notifications from "vue-notification";
import FloatingVue from "floating-vue";

Vue.use(FloatingVue);

Vue.config.productionTip = false;

Vue.use(VModal);
Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(Notifications);

import Default from "@/layouts/Default.vue";
import Empty from "@/layouts/Empty.vue";

Vue.component("Default", Default);
Vue.component("Empty", Empty);

const yandexMetricaId = process.env.VUE_APP_YANDEX_METRICA_ID;

if (yandexMetricaId) {
  import("./utils/analytics").then(() => {
    router.afterEach((to) => {
      if (window.ym) {
        window.ym(yandexMetricaId, "hit", to.fullPath);
      }
    });
  });
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
